import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import * as _ from 'lodash';

import { Grid, Container, Divider } from '@material-ui/core';
import Helmet from 'react-helmet';

import BaseHOC from './BaseHOC';
import SEO from './seo';
import BookingHero from './BookingHero';
import AboutUs from './AboutUs';
import Services from './Services';
import Barbers from './Barbers';
import Contact from './Contact';
import OpeningHours from './OpeningHours';
import BookingFooter from './BookingFooter';

const PaddedGrid = styled(Grid)`
  && {
    padding: 2rem 0rem;
  }
`;

// #1A60B2
// #FCFCFC
// #D42861

const ShopDetails = ({ shopDetails }) => {
  const aggregatedResultsSnippet = {
    '@context': 'https://schema.org/',
    '@type': 'AggregateRating',
    itemReviewed: {
      '@type': 'HairSalon',
      image: shopDetails.hero_image,
      name: shopDetails.name,
    },
    ratingValue: shopDetails.rating_mean,
    bestRating: '5',
    ratingCount: shopDetails.rating_count,
  };
  const { formatMessage: f } = useIntl();

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(aggregatedResultsSnippet)}
        </script>
      </Helmet>
      <SEO
        title={`${shopDetails.name} | ${f({ id: 'shopDetailsBooking' })}`}
        description={shopDetails.meta_description}
        thumbnailPreview={shopDetails.hero_image}
      />
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        alignContent="center"
      >
        <Grid item xs={12}>
          <BookingHero
            heroImageSrc={shopDetails.hero_image}
            title={shopDetails.name}
            ctaText={
              shopDetails.disable_booking_pages
                ? null
                : f({ id: 'shopDetailsBookNow' })
            }
            ctaAction={() => navigate(`/booking/${shopDetails.slug}/`)}
          />
        </Grid>
        {shopDetails?.hide_service_component ? null : (
          <PaddedGrid item xs={12}>
            <Services
              services={shopDetails.services}
              shopSlug={shopDetails.slug}
              country={shopDetails.country}
              disabled={shopDetails.disable_booking_pages}
            />
          </PaddedGrid>
        )}
        <PaddedGrid item xs={12}>
          <Barbers
            barbers={
              shopDetails.barbers &&
              shopDetails.barbers.filter((x) => !x.disable_booking)
            }
            shopSlug={shopDetails.slug}
            disabled={shopDetails.disable_booking_pages}
          />
        </PaddedGrid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {shopDetails.about_us ? (
          <>
            <Grid item xs={12}>
              <AboutUs aboutUs={shopDetails.about_us} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        ) : null}
        <PaddedGrid item xs={12}>
          <Container maxWidth="md">
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6}>
                <Contact shopDetails={shopDetails} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <OpeningHours openingHours={shopDetails.opening_hours} />
              </Grid>
            </Grid>
          </Container>
        </PaddedGrid>
        <Grid item xs={12}>
          <BookingFooter />
        </Grid>
      </Grid>
    </>
  );
};

export default BaseHOC(ShopDetails);
