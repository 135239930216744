import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box, Container, List } from '@material-ui/core';

import SecondaryButton from './SecondaryButton';
import ServiceListItem from './ServiceListItem';

import { ccyFormat } from '../utils';

const Services = ({ services, shopSlug, country, disabled }) => {
  const { formatMessage: f } = useIntl();
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom align="center">
          {f({ id: 'appNavAdminShopServices' })}
        </Typography>
        <List component="nav">
          {services.slice(0, showAll ? undefined : 5).map((service) => (
            <ServiceListItem
              key={service.id}
              title={service.title}
              description={service.description}
              price={ccyFormat(service.price, country && country.currency)}
              levels={service.service_levels}
              fixPrice={service.fix_price}
              to={disabled ? '#' : `/booking/${shopSlug}/${service.base_id}/`}
            />
          ))}
        </List>
      </Container>
      <Grid container justifyContent="center">
        {showAll || services.length <= 5 ? null : (
          <Box pt="2rem">
            <SecondaryButton
              variant="outlined"
              onClick={() => setShowAll(true)}
            >
              {f({ id: 'bookingMoreServices' })}
            </SecondaryButton>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default Services;
