import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
} from '@material-ui/core';

import { Link as GatsbyLink } from 'gatsby';

const StyledListItem = styled(ListItem)`
  &:hover {
    color: inherit;
  }
`;

const LimitedListItemText = styled(ListItemText)`
  && {
    max-width: 60%;
  }
`;

function ServiceListItem({
  to,
  onClick,
  title,
  description,
  price,
  levels,
  fixPrice,
}) {
  const { formatMessage: f } = useIntl();
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
        <GatsbyLink to={to} {...itemProps} innerRef={ref} />
      )),
    [to],
  );

  return (
    <StyledListItem
      divider
      button
      component={renderLink}
      onClick={() => (onClick ? onClick() : null)}
    >
      <LimitedListItemText primary={title} secondary={description} />
      <ListItemSecondaryAction>
        <ListItemText
          primary={
            (levels && levels.length > 0) || !fixPrice
              ? f({ id: 'barberMinimumServicePriceFormat' }, { price })
              : price
          }
        />
      </ListItemSecondaryAction>
    </StyledListItem>
  );
}

export default ServiceListItem;
