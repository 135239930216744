import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Typography, Container, Box } from '@material-ui/core';

const BodyText = styled(Typography).attrs((props) => ({
  variant: 'body1',
  gutterBottom: true,
  align: 'left',
}))`
  && {
    max-width: 600px;
  }
`;

const BarberBox = styled(Box).attrs((props) => ({
  height: '100%',
  minHeight: '15rem',
  color: '#fcfcfc',
  pt: '2rem',
  bgcolor: '#1a60b2',
}))``;

const AboutUs = ({ aboutUs }) => {
  const { formatMessage: f } = useIntl();
  return (
    <BarberBox>
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom align="center">
          {f({ id: 'bookingAboutUs' })}
        </Typography>
        <BodyText style={{ whiteSpace: 'pre-line' }}>{aboutUs}</BodyText>
      </Container>
    </BarberBox>
  );
};

export default AboutUs;
