import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box, Container } from '@material-ui/core';

import SecondaryButton from './SecondaryButton';
import BarberListItem from './BarberListItem';

const Barbers = ({ barbers, shopSlug, disabled }) => {
  // Show all by default
  const [showAll, setShowAll] = useState(true);
  const topBarberCount =
    barbers.filter((x) => parseFloat(x.start_rating) >= 4).length || 4;
  const { formatMessage: f } = useIntl();
  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom align="center">
          {f({ id: 'bookingBarbers' })}
        </Typography>
        <Box
          display="flex"
          width="100%"
          flexWrap="wrap"
          justifyContent="center"
        >
          {barbers
            .sort(
              (a, b) =>
                (parseFloat(b.star_rating) || 0) +
                b.ordering_boost -
                ((parseFloat(a.star_rating) || 0) + a.ordering_boost),
            )
            .slice(0, showAll ? undefined : topBarberCount)
            .map((barber) => (
              <BarberListItem
                key={barber.id}
                barber={barber}
                toBooking={`/booking/${shopSlug}/${barber.slug}`}
                toBarber={`/shop/${shopSlug}/barbers/${barber.slug}`}
                disabled={disabled}
              />
            ))}
        </Box>
      </Container>
      <Grid container justifyContent="center">
        {showAll || barbers.length <= topBarberCount ? null : (
          <Box pt="2rem">
            <SecondaryButton
              onClick={() => setShowAll(true)}
              variant="outlined"
            >
              {f({ id: 'bookingBarbersMore' })}
            </SecondaryButton>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default Barbers;
