import React from 'react';
import { IntlProvider } from 'react-intl';

import { isSalonzone } from '../utils';
import messages from '../data/messages';
import salonzoneMessages from '../data/messages/salonzone';
import ShopDetails from '../components/ShopDetails';
import FontSelector from '../components/FontSelector';

const ShopDetailsTemplate = ({ pageContext: { shopDetails } }) => {
  const locale =
    shopDetails && shopDetails.country && shopDetails.country.language_key;

  return (
    <IntlProvider
      locale={locale}
      messages={isSalonzone ? salonzoneMessages[locale] : messages[locale]}
    >
      <FontSelector>
        <ShopDetails shopDetails={shopDetails} />
      </FontSelector>
    </IntlProvider>
  );
};

export default ShopDetailsTemplate;
