import React from 'react';
import { useIntl } from 'react-intl';
import { navigate } from 'gatsby';
import {
  Typography,
  Box,
  Card,
  Avatar,
  CardContent,
  IconButton,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PersonIcon from '@material-ui/icons/Person';

import StarRating from '../StarRating';
import PrimaryButton from '../PrimaryButton';

function BarberListItem({ barber, toBarber, toBooking, disabled }) {
  const { formatMessage: f } = useIntl();
  return (
    <Card style={{ margin: 10, width: 200 }}>
      <CardContent>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box>
            <Avatar
              aria-label="recipe"
              style={{
                backgroundColor: 'white',
                color: 'black',
                width: 128,
                height: 128,
              }}
              src={
                barber &&
                barber.profile_image &&
                `https://agrddpxdbm.cloudimg.io/v7/${barber.profile_image}?w=300&h=300&func=face`
              }
            >
              {barber && barber.profile_image ? null : (
                <PersonIcon style={{ fontSize: 128 }} />
              )}
            </Avatar>
          </Box>
          <Box mt={1} display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body1" color="textPrimary" component="p">
              {barber.display_name}
            </Typography>
            <IconButton
              aria-label="Profil"
              size="small"
              onClick={() => navigate(toBarber)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Box>
          {barber.service_levels.length > 0 && (
            <Box alignItems="center" textAlign="center">
              <Typography variant="body2" color="textPrimary" component="p">
                {[
                  ...new Set(
                    barber.service_levels
                      .filter((x) => x.length > 2)
                      .map((x) => x.level.title),
                  ),
                ].join(', ')}
              </Typography>
            </Box>
          )}
          {barber.star_rating ? (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              mb={1}
              onClick={() => navigate(toBarber)}
              style={{ cursor: 'pointer' }}
            >
              {parseFloat(barber.star_rating) > 3 ? (
                <>
                  <StarRating rating={barber.star_rating || 0} />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {`(${barber.review_count})`}
                  </Typography>
                </>
              ) : null}
            </Box>
          ) : null}
          {!disabled && (
            <Box>
              <PrimaryButton
                nomargin="true"
                danger
                onClick={() => navigate(toBooking)}
              >
                {f({ id: 'bookingBarbersBook' })}
              </PrimaryButton>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default BarberListItem;
